// Classes to be applied for high ppi displays
// -------------------------
@mixin retina(){
  @media (min--moz-device-pixel-ratio: 1.3),
           (-o-min-device-pixel-ratio: 2.6/2),
      (-webkit-min-device-pixel-ratio: 1.3),
              (min-device-pixel-ratio: 1.3),
                  (min-resolution: 1.3dppx) {
    @content;
  }
}

// Common thumbnail properties
// -------------------------
@mixin thumb($parent, $size){
  #{$parent} {
    width: $size;

    > .glyphicon,
    > .fa{
      line-height: $size;
    }
  }
}


// Generates single property classes
// -------------------------
@mixin property-variants($base, $property, $variants: ()){
  @each $size, $value in $variants {
    @if $size == 'md'{
      #{$base} {
        #{$property}: $value;
      }
      #{$base}-n {
        #{$property}: $value;
      }
    }
    #{$base}-#{$size} {
      #{$property}: $value;
    }
    #{$base}-n-#{$size} {
      #{$property}: -$value;
    }
  }
}

@mixin keyframes($name){
  @-webkit-keyframes #{$name} {
    @content;
  }

  @keyframes #{$name} {
    @content;
  }
}

@mixin border-radius($radius) {
  border-radius: $radius;
}
