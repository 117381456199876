/**
 * override custom libs styles. This files is not required for core sing functionality and may be removed
 */

/**
 * Switchery.
 */

.switchery{
  @include box-sizing(content-box);
  margin-left: -20px;
}

/**
 * Datetimepicker for Bootstrap v3
 */
.bootstrap-datetimepicker-widget
{
  border-radius: $border-radius-base;

  td,
  th
  {
    border-radius: $border-radius-base;
  }

  td
  {
    &.day:hover,
    &.hour:hover,
    &.minute:hover,
    &.second:hover
    {
      background: $gray-lighter;
    }

    &.old,
    &.new
    {
      color: $gray-light;
    }

    &.active,
    &.active:hover
    {
      background-color: $btn-primary-bg;
      text-shadow: none;
    }

    &.disabled,
    &.disabled:hover
    {
      color: $gray-light;
    }

    span
    {
      border-radius: $border-radius-base;

      &:hover
      {
        background: $gray-lighter;
      }

      &.active
      {
        background-color: $btn-primary-bg;
      }

      &.old
      {
        color: $gray-light;
      }

      &.disabled,
      &.disabled:hover
      {
        color: $gray-light;
      }
    }
  }
  th
  {
    &.next,
    &.prev
    {
      font-size: $font-size-base * 1.5;
    }
    &.disabled,
    &.disabled:hover
    {
      color: $gray-light;
    }
  }
  thead tr:first-child th
  {
    &:hover
    {
      background: $gray-lighter;
    }
  }
}

/**
 * Bootstrap Colorpicker
 * setting correct urls
 */

.colorpicker-saturation {
  background-image: url("#{$vendor-folder}/mjolnic-bootstrap-colorpicker/dist/img/bootstrap-colorpicker/saturation.png");;
}

.colorpicker-hue {
  background-image: url("#{$vendor-folder}/mjolnic-bootstrap-colorpicker/dist/img/bootstrap-colorpicker/hue.png");
}

.colorpicker-alpha,
.colorpicker-color {
  background-image: url("#{$vendor-folder}/mjolnic-bootstrap-colorpicker/dist/img/bootstrap-colorpicker/alpha.png");
}

.colorpicker.colorpicker-horizontal .colorpicker-hue {
  background-image: url("#{$vendor-folder}/mjolnic-bootstrap-colorpicker/dist/img/bootstrap-colorpicker/hue-horizontal.png");
}

.colorpicker.colorpicker-horizontal .colorpicker-alpha {
  background-image: url("#{$vendor-folder}/mjolnic-bootstrap-colorpicker/dist/img/bootstrap-colorpicker/alpha-horizontal.png");
}


/**
 * Jasny Bootstrap
 * Fileinput.less
 */


.fileinput-new.input-group .btn-file,
.fileinput-new .input-group .btn-file {
  border-radius: 0 $border-radius-base $border-radius-base 0;

  &.btn-xs,
  &.btn-sm {
    border-radius: 0 $border-radius-small $border-radius-small 0;
  }
  &.btn-lg {
    border-radius: 0 $border-radius-large $border-radius-large 0;
  }
}

.form-group.has-warning .fileinput {
  .fileinput-preview {
    color: $state-warning-text;
  }
  .thumbnail {
    border-color: $state-warning-border;
  }
}
.form-group.has-error .fileinput {
  .fileinput-preview {
    color: $state-danger-text;
  }
  .thumbnail {
    border-color: $state-danger-border;
  }
}
.form-group.has-success .fileinput {
  .fileinput-preview {
    color: $state-success-text;
  }
  .thumbnail {
    border-color: $state-success-border;
  }
}


/**
 * Dropzone
 */

.dropzone{
  border: 2px dashed #ccc;
  @include border-radius($border-radius-base);
}
/**
 * Mapael
 */

.mapTooltip {
  position : fixed;
  padding : 2px;
  z-index: 1000;
  max-width: 200px;
  display: none;
  background-color : #fff;
  border: 1px solid #ccc;
  border-radius: $border-radius-base;
  font-size: $font-size-small;
  color: $text-color;
}

.zoomIn, .zoomOut {
  position: absolute;
  bottom: 10px;
  left : 10px;
  width: 20px;
  height: 20px;

  @include box-sizing(content-box);
  border: 1px solid #ccc;
  background-color: #fff;
  color: $text-color;
  line-height: 20px;
  text-align: center;
  border-radius: $border-radius-base;
  cursor: pointer;
  font-weight: $font-weight-bold;

  @include user-select(none);
}

.zoomOut {
  left: 36px;

}

  .mapael{
  position: relative;
  margin: (-$widget-padding-vertical) (-$widget-padding-horizontal) 0;
  .map {
    position:relative;
    svg{
      height: inherit;
    }
  }
  .stats{
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    margin: 5% 10%;
  }
}

/**
 * nvd3
 */
.nvtooltip {
  padding: $padding-small-vertical $padding-small-horizontal;
  font-family: $font-family-base;
  font-size: $font-size-small;
  text-align: center;

  p{
    margin: 0;
    padding: 0;
  }

  h3{
    background: none;
    border-bottom: 0;
  }
}

svg text {
  font: $font-weight-base $font-size-small $font-family-base;
  fill: $text-color;
}

svg .title {
  font: $font-weight-bold $font-size-base $font-family-base;
}

.nvd3.nv-noData {
  font-size: $font-size-large;
  font-weight: $font-weight-bold;
}

.nvd3 .nv-axis path.domain {
  stroke-opacity: 0;
}

.nv-controlsWrap{
  @include translate(-40px, -50px);

  .nv-legend-symbol{
    fill: #666 !important;
    stroke: #666 !important;
  }
}

/**
 * Markdown. converted from less files
 */

.md-editor {
  display: block;
  border: 1px solid $table-border-color;

  > .md-header, .md-footer {
    display: block;
    padding: 6px 4px;
    background: $panel-default-heading-bg;
  }

  > .md-header {
    margin: 0;
  }

  > .md-preview {
    padding: $padding-base-vertical $padding-base-horizontal;
    background: $panel-bg;
    border-top: 1px dashed $table-border-color;
    min-height: 10px;
  }

  > textarea {
    font-family: $font-family-monospace;
    font-size: $font-size-base;
    outline: 0;
    outline: thin dotted  \9; /* IE6-9 */
    margin: 0;
    display: block;
    width: 100%;
    border: 0;
    border-top: 1px dashed $table-border-color;
    border-radius: 0;
    box-shadow: none;
    background: $input-bg-disabled;
    &:focus {
      box-shadow: none;
      background: $input-bg;
    }
  }

  // Hover state
  $color: $input-border-focus;
  &.active {
    border-color: $color;
    outline: 0;
  }
}

/**
 * Bootstrap slider. converted from less files
 */

$slider-line-height: 8px;
$slider-handle-size: 26px;

.slider {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  &.slider-horizontal {
    width: 210px;
    height: $slider-line-height;
    .slider-track {
      height: $slider-line-height/2;
      width: 100%;
      margin-top: -$slider-line-height/4;
      top: 50%;
      left: 0;
    }
    .slider-selection {
      height: 100%;
      top: 0;
      bottom: 0;
    }
    .slider-handle {
      margin-left: -$slider-handle-size/2;
      margin-top: -$slider-handle-size*3/8;
      &.triangle {
        border-width: 0 $slider-line-height/2 $slider-line-height/2 $slider-line-height/2;
        width: 0;
        height: 0;
        border-bottom-color: #0480be;
        margin-top: 0;
      }
    }
  }
  &.slider-vertical {
    height: 210px;
    width: $slider-line-height;
    .slider-track {
      width: $slider-line-height/2;
      height: 100%;
      margin-left: -$slider-line-height/4;
      left: 50%;
      top: 0;
    }
    .slider-selection {
      width: 100%;
      left: 0;
      top: 0;
      bottom: 0;
    }
    .slider-handle {
      margin-left: -$slider-handle-size*3/8;
      margin-top: -$slider-handle-size/2;
      &.triangle {
        border-width: $slider-line-height/2 0 $slider-line-height/2 $slider-line-height/2;
        width: 1px;
        height: 1px;
        border-left-color: #0480be;
        margin-left: 0;
      }
    }
  }
  &.slider-disabled {
    .slider-handle {
      @include gradient-vertical(#dfdfdf, #bebebe);
    }
    .slider-track {
      @include gradient-vertical(#e5e5e5, #e9e9e9);
      cursor: not-allowed;
    }
  }
  input {
    display: none;
  }
  .tooltip-inner {
    white-space: nowrap;
  }

  .tooltip.top {
    top: -30px;
  }
}
.slider-track {
  position: absolute;
  cursor: pointer;
  @include gradient-vertical(#eee, #f8f8f8);

  @include box-shadow(inset 0 1px 2px rgba(0,0,0,.1));
  border-radius: $border-radius-base;
}
.slider-selection {
  position: absolute;
  background-color: $brand-primary;
  background-image: none;
  @include box-shadow(inset 0 -1px 0 rgba(0,0,0,.15));
  @include box-sizing(border-box);
  border-radius: $border-radius-base;

  .slider-danger &{
    background-color: $brand-danger;
  }
  .slider-success &{
    background-color: $brand-success;
  }
  .slider-warning &{
    background-color: $brand-warning;
  }
  .slider-info &{
    background-color: $brand-info;
  }
  .slider-inverse &{
    background-color: $gray;
  }
}
.slider-handle {
  position: absolute;
  width: $slider-handle-size;
  height: $slider-handle-size;
  background-color: $white;
  background-image: none;
  @include box-shadow(inset 0 1px 0 rgba(255,255,255,.2), 0 1px 3px rgba(0,0,0,.5));
  border: 0 solid transparent;

  &:focus{
    outline: 0;
  }

  &.round {
    border-radius: 50%;
  }
  &.triangle {
    background: transparent none;
  }
}


/*
 * Bootstrap calendar
 */

.calendar{
  font-size: $font-size-mini;
  padding: $line-height-computed/2 0;
  background-color: rgb(222, 228, 238);
  border: none;
}
.calendar a{
  text-decoration: none;
  cursor: pointer;
  color: $gray-dark;
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  position: relative;
}
.calendar td{
  text-align: center;
}
.calendar .header{
  padding: 10px 0 10px 0;
  color: #666;
  i{
    cursor: pointer;
  }
}
.calendar .prev_month{
  float: left;
}
.calendar .next_month{
  float: right;
}
.calendar .year{
  text-align: center;
  font-weight: 500;
}
.calendar .week_days{
  color: #999;
  background-color: rgb(222, 228, 238);
}
.calendar .event{
  color: white;
  font-weight: bold;
  background: rgb(222, 228, 238);
  box-shadow: none;

  &:hover{
    background: rgb(222, 228, 238);
    box-shadow: none;
    > a{
      background-color: $gray-lighter;
    }
  }

  &, &:hover, > a, > a:hover {
    text-shadow: none;
  }

  > a {
    color: black;
    background-color: $white;
    @include border-radius(50%);

    > span{
      display: inline-block;
      width: 6px;
      height: 6px;
      position: absolute;
      bottom: 1px;
      right: 1px;
      @include border-radius(50%);
    }
  }

  .popover{
    color: $text-color;
  }
}
.calendar .table td{
  padding: 1px 0;
  border-top: 0;
}
.calendar .table{
  margin-bottom: 3px;
  &.header{
    margin-bottom: $line-height-computed/2;
  }
}
.calendar .week_days td{
  background-color: rgb(222, 228, 238);
  color: #999;
}
.calendar .visualmonthyear {
  color: $gray-light;
}
.dropdown-menu .calendar td a {
  padding: 0px;
}

/***********************************/
/**            PARSLEY            **/
/***********************************/


input.parsley-error{
  border-color: $brand-danger;
  @include box-shadow(inset 0 1px 1px rgba($brand-danger,.075));
}

.parsley-errors-list{
  font-size: $font-size-small;
  padding-left: 0;
  margin-bottom: 5px;

  li{
    list-style: none;
    color: $brand-danger;
  }
}

/***********************************/
/**          FORM WIZARD          **/
/***********************************/

.form-wizard{
  .nav-pills{
    > li > a{
      padding-top: 15px;
      padding-bottom: 15px;
      background-color: $gray-lighter;
      color: $gray-light;
      @include border-radius(3px);
      cursor: default;
    }

    > li.active > a{
      background-color: $brand-primary;
      color: $white;
    }
    > li.done > a {
      background-color: lighten($brand-primary, 28%);
      color: $white;
      cursor: pointer;
    }
    @media (min-width: $screen-sm-min) {
      &.nav-justified{
        > li {
          padding: 0 5px;
          &:first-child{
            padding-left: 0;
          }
          &:last-child{
            padding-right: 0;
          }
        }
      }
    }
  }

  .tab-pane{
    padding: $line-height-computed 20px;
    @include border-radius(5px);
  }

  .pager > li.disabled > .btn{
    cursor: not-allowed;
    pointer-events: none; // Future-proof disabling of clicks
    @include opacity(.65);
    @include box-shadow(none);
  }

  //fix conflict with MODAL WIZARD
  .pager.wizard{
    display: block;
  }

}

/***********************************/
/**         MODAL WIZARD          **/
/***********************************/

.wizard-header h3 {
  font-family: $font-family-base;
  font-weight: $headings-font-weight;
  color: $text-color;
}

.wizard-nav-list > li.already-visited > a.wizard-nav-link,
.wizard-nav-list > li.already-visited.active > a.wizard-nav-link{
  color: $text-color;
}

.wizard-nav-list li.active > a,
.wizard-nav-item .already-visited .active{
  background-color: $brand-info;
  color: $white;
}

.wizard-dialog .popover.error-popover .arrow {
  border-right-color: transparent;
}
.wizard-body{
  padding: 0;
  .wizard-card{
    border: none;
  }
}

/***********************************/
/**           Backgrid            **/
/***********************************/
.backgrid-paginator {
  text-align: left;
}
.table-editable{

  th > a{
    color: $text-color;
    cursor: pointer;
  }

  .sort-caret {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-left: 0.3em;
    border: 0;
    content: "";
    font: 15px FontAwesome;
  }

  .ascending .sort-caret {
    &:before{
      content: "\f0d8";
    }
  }

  .descending .sort-caret {
    &:before{
      content: "\f0d7";
    }
  }

  + .backgrid-paginator > ul{
    font-weight: $font-weight-normal;
    display: inline-block;
    padding-left: 0;
    margin: $line-height-computed 0;
    border-radius: $border-radius-base;

    > li {
      display: inline; // Remove list-style and block-level defaults
      > a,
      > span {
        position: relative;
        float: left; // Collapse white-space
        line-height: $line-height-base;
        text-decoration: none;
        color: $pagination-color;
        background-color: $pagination-bg;
        border: 1px solid $pagination-border;
        border-radius: $border-radius-base;
        margin: 0 2px;
        width: inherit;

        > .fa-lg{
          position: relative;
          top: -1px;
        }
      }
      &:first-child {
        > a,
        > span {
          margin-left: 0;
        }
      }
    }

    > li > a,
    > li > span {
      &:hover,
      &:focus {
        color: $pagination-hover-color;
        background-color: $pagination-hover-bg;
        border-color: $pagination-hover-border;
      }
    }

    > .active > a,
    > .active > span {
      &,
      &:hover,
      &:focus {
        z-index: 2;
        color: $pagination-active-color;
        background-color: $pagination-active-bg;
        border-color: $pagination-active-border;
        cursor: default;
      }
    }

    > .disabled {
      > span,
      > span:hover,
      > span:focus,
      > a,
      > a:hover,
      > a:focus {
        color: $pagination-disabled-color;
        background-color: $pagination-disabled-bg;
        border-color: $pagination-disabled-border;
        cursor: not-allowed;
      }
    }

    @include pagination-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $border-radius-small);
  }
}

/***********************************/
/**          Datatables           **/
/***********************************/

table.dataTable thead .sorting {
  background: url("../assets/datatables/sort_both.png") no-repeat center right;
}
table.dataTable thead .sorting_asc {
  background: url("../assets/datatables/sort_asc.png") no-repeat center right;
}
table.dataTable thead .sorting_desc {
  background: url("../assets/datatables/sort_desc.png") no-repeat center right;
}
table.dataTable thead .sorting_asc_disabled {
  background: url("../assets/datatables/sort_asc_disabled.png") no-repeat center right;
}
table.dataTable thead .sorting_desc_disabled {
  background: url("../assets/datatables/sort_desc_disabled.png") no-repeat center right;
}

table.dataTable thead th,
table.dataTable thead td,
table.dataTable.no-footer{
  border-bottom-color: transparent;
}

table.dataTable thead th,
table.dataTable tfoot th{
  font-weight: $font-weight-semi-bold;
}

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate{
  color: $text-color;
}

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate{
  font-size: $font-size-mini;
  color: $gray-semi-light;
}

/**
 * Nestable
 */

.dd {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  max-width: 600px;
  list-style: none;
  font-size: 13px;
  line-height: 20px;
}

.dd-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}

.dd-list .dd-list {
  padding-left: 30px;
}

.dd-collapsed .dd-list {
  display: none;
}

.dd-item,
.dd-empty,
.dd-placeholder {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 20px;
  font-size: 13px;
  line-height: 20px;
}

.dd-handle {
  display: block;
  height: 30px;
  margin: 5px 0;
  padding: 5px 10px;
  color: $text-color;
  text-decoration: none;
  background: $white;
  border: 1px solid $list-group-border;
  border-radius: $border-radius-base;

  @include box-sizing(border-box);
}

.dd-item > button {
  display: block;
  position: relative;
  cursor: pointer;
  float: left;
  width: 25px;
  height: 20px;
  margin: 5px 0;
  padding: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 0;
  background: transparent;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  font-weight: bold;
  color: $text-color;

  &:focus,
  &:active{
    outline: 0;
  }
}

.dd-item > button:before {
  content: '+';
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  text-indent: 0;
}

.dd-item > button[data-action="collapse"]:before {
  content: '-';
}

.dd-placeholder,
.dd-empty {
  margin: 5px 0;
  padding: 0;
  min-height: 30px;
  background: $gray-semi-lighter;
  border: 1px dashed $gray-light;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  border-radius: $border-radius-base;
}

.dd-empty {
  border: 1px dashed $gray-light;
  min-height: 100px;
  background-size: 60px 60px;
  background-position: 0 0, 30px 30px;

  background-image: -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff),
  -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-image: linear-gradient(45deg, #FFF 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 75%, #FFF 75%, #FFF),
  linear-gradient(45deg, #FFF 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 75%, #FFF 75%, #FFF);
}

.dd-dragel {
  position: absolute;
  pointer-events: none;
  z-index: 9999;
}

.dd-dragel > .dd-item .dd-handle {
  margin-top: 0;
}

.dd-dragel .dd-handle {
  -webkit-box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, .1);
  box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, .1);
}

/**
 * Nestable Extras
 */

.nestable-lists {
  display: block;
  clear: both;
  padding: 30px 0;
  width: 100%;
  border: 0;
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
}

@media only screen and (min-width: 700px) {
  .dd + .dd {
    margin-left: 2%;
  }

}

.dd-hover > .dd-handle {
  background: #2ea8e5 !important;
}

/**
 * Nestable Draggable Handles
 */

.dd3-content {
  display: block;
  height: 30px;
  margin: 5px 0;
  padding: 5px 10px 5px 40px;
  color: #333;
  text-decoration: none;
  font-weight: bold;
  border: 1px solid #ccc;
  background: #fafafa;
  background: -webkit-linear-gradient(top, #fafafa 0%, #eee 100%);
  background: -moz-linear-gradient(top, #fafafa 0%, #eee 100%);
  background: linear-gradient(top, #fafafa 0%, #eee 100%);
  -webkit-border-radius: 3px;
  border-radius: 3px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.dd3-content:hover {
  color: #2ea8e5;
  background: #fff;
}

.dd-dragel > .dd3-item > .dd3-content {
  margin: 0;
}

.dd3-item > button {
  margin-left: 30px;
}

.dd3-handle {
  position: absolute;
  margin: 0;
  left: 0;
  top: 0;
  cursor: pointer;
  width: 30px;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 1px solid #aaa;
  background: #ddd;
  background: -webkit-linear-gradient(top, #ddd 0%, #bbb 100%);
  background: -moz-linear-gradient(top, #ddd 0%, #bbb 100%);
  background: linear-gradient(top, #ddd 0%, #bbb 100%);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.dd3-handle:before {
  content: '≡';
  display: block;
  position: absolute;
  left: 0;
  top: 3px;
  width: 100%;
  text-align: center;
  text-indent: 0;
  color: #fff;
  font-size: 20px;
  font-weight: normal;
}

.dd3-handle:hover {
  background: #ddd;
}

/***********************/
/*      Messenger      */
/***********************/

//copying theme-air here as we don't want to use Raleway font included into theme-air.css

@-webkit-keyframes ui-spinner-rotate-right {
  /* line 64, ../../src/sass/messenger-spinner.scss */
  0% {
    -webkit-transform: rotate(0deg);
  }

  /* line 65, ../../src/sass/messenger-spinner.scss */
  25% {
    -webkit-transform: rotate(180deg);
  }

  /* line 66, ../../src/sass/messenger-spinner.scss */
  50% {
    -webkit-transform: rotate(180deg);
  }

  /* line 67, ../../src/sass/messenger-spinner.scss */
  75% {
    -webkit-transform: rotate(360deg);
  }

  /* line 68, ../../src/sass/messenger-spinner.scss */
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes ui-spinner-rotate-left {
  /* line 72, ../../src/sass/messenger-spinner.scss */
  0% {
    -webkit-transform: rotate(0deg);
  }

  /* line 73, ../../src/sass/messenger-spinner.scss */
  25% {
    -webkit-transform: rotate(0deg);
  }

  /* line 74, ../../src/sass/messenger-spinner.scss */
  50% {
    -webkit-transform: rotate(180deg);
  }

  /* line 75, ../../src/sass/messenger-spinner.scss */
  75% {
    -webkit-transform: rotate(180deg);
  }

  /* line 76, ../../src/sass/messenger-spinner.scss */
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes ui-spinner-rotate-right {
  /* line 80, ../../src/sass/messenger-spinner.scss */
  0% {
    -moz-transform: rotate(0deg);
  }

  /* line 81, ../../src/sass/messenger-spinner.scss */
  25% {
    -moz-transform: rotate(180deg);
  }

  /* line 82, ../../src/sass/messenger-spinner.scss */
  50% {
    -moz-transform: rotate(180deg);
  }

  /* line 83, ../../src/sass/messenger-spinner.scss */
  75% {
    -moz-transform: rotate(360deg);
  }

  /* line 84, ../../src/sass/messenger-spinner.scss */
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-moz-keyframes ui-spinner-rotate-left {
  /* line 88, ../../src/sass/messenger-spinner.scss */
  0% {
    -moz-transform: rotate(0deg);
  }

  /* line 89, ../../src/sass/messenger-spinner.scss */
  25% {
    -moz-transform: rotate(0deg);
  }

  /* line 90, ../../src/sass/messenger-spinner.scss */
  50% {
    -moz-transform: rotate(180deg);
  }

  /* line 91, ../../src/sass/messenger-spinner.scss */
  75% {
    -moz-transform: rotate(180deg);
  }

  /* line 92, ../../src/sass/messenger-spinner.scss */
  100% {
    -moz-transform: rotate(360deg);
  }
}

@keyframes ui-spinner-rotate-right {
  /* line 96, ../../src/sass/messenger-spinner.scss */
  0% {
    transform: rotate(0deg);
  }

  /* line 97, ../../src/sass/messenger-spinner.scss */
  25% {
    transform: rotate(180deg);
  }

  /* line 98, ../../src/sass/messenger-spinner.scss */
  50% {
    transform: rotate(180deg);
  }

  /* line 99, ../../src/sass/messenger-spinner.scss */
  75% {
    transform: rotate(360deg);
  }

  /* line 100, ../../src/sass/messenger-spinner.scss */
  100% {
    transform: rotate(360deg);
  }
}

@keyframes ui-spinner-rotate-left {
  /* line 104, ../../src/sass/messenger-spinner.scss */
  0% {
    transform: rotate(0deg);
  }

  /* line 105, ../../src/sass/messenger-spinner.scss */
  25% {
    transform: rotate(0deg);
  }

  /* line 106, ../../src/sass/messenger-spinner.scss */
  50% {
    transform: rotate(180deg);
  }

  /* line 107, ../../src/sass/messenger-spinner.scss */
  75% {
    transform: rotate(180deg);
  }

  /* line 108, ../../src/sass/messenger-spinner.scss */
  100% {
    transform: rotate(360deg);
  }
}

/* line 116, ../../src/sass/messenger-spinner.scss */
.messenger-spinner {
  position: relative;
  border-radius: 100%;
}
/* line 120, ../../src/sass/messenger-spinner.scss */
ul.messenger.messenger-spinner-active .messenger-spinner .messenger-spinner {
  display: block;
}
/* line 124, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
}
/* line 130, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side .messenger-spinner-fill {
  border-radius: 999px;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -ms-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
}
/* line 140, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side-left {
  left: 0;
}
/* line 143, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side-left .messenger-spinner-fill {
  left: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  -webkit-animation-name: ui-spinner-rotate-left;
  -moz-animation-name: ui-spinner-rotate-left;
  -ms-animation-name: ui-spinner-rotate-left;
  -o-animation-name: ui-spinner-rotate-left;
  animation-name: ui-spinner-rotate-left;
  -webkit-transform-origin: 0 50%;
  -moz-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
  -o-transform-origin: 0 50%;
  transform-origin: 0 50%;
}
/* line 152, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side-right {
  left: 50%;
}
/* line 155, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side-right .messenger-spinner-fill {
  left: -100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  -webkit-animation-name: ui-spinner-rotate-right;
  -moz-animation-name: ui-spinner-rotate-right;
  -ms-animation-name: ui-spinner-rotate-right;
  -o-animation-name: ui-spinner-rotate-right;
  animation-name: ui-spinner-rotate-right;
  -webkit-transform-origin: 100% 50%;
  -moz-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  -o-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}

/* line 16, ../../src/sass/messenger-theme-air.sass */
ul.messenger-theme-air {
  -moz-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  font-family: "Raleway", sans-serif;
}
/* line 20, ../../src/sass/messenger-theme-air.sass */
ul.messenger-theme-air .messenger-message {
  -webkit-transition: background-color 0.4s;
  -moz-transition: background-color 0.4s;
  -o-transition: background-color 0.4s;
  transition: background-color 0.4s;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 0 1px white, inset 0 2px white, 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0 0 0 1px white, inset 0 2px white, 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 0 1px white, inset 0 2px white, 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px rgba(0, 0, 0, 0.2);
  border: 0px;
  background-color: rgba(255, 255, 255, 0.8);
  position: relative;
  margin-bottom: 1em;
  font-size: 13px;
  color: #666666;
  font-weight: 500;
  padding: 10px 30px 11px 46px;
}
/* line 33, ../../src/sass/messenger-theme-air.sass */
ul.messenger-theme-air .messenger-message:hover {
  background-color: white;
}
/* line 36, ../../src/sass/messenger-theme-air.sass */
ul.messenger-theme-air .messenger-message .messenger-close {
  position: absolute;
  top: 0px;
  right: 0px;
  color: #888888;
  opacity: 1;
  font-weight: bold;
  display: block;
  font-size: 20px;
  line-height: 20px;
  padding: 8px 10px 7px 7px;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}
/* line 52, ../../src/sass/messenger-theme-air.sass */
ul.messenger-theme-air .messenger-message .messenger-close:hover {
  color: #444444;
}
/* line 55, ../../src/sass/messenger-theme-air.sass */
ul.messenger-theme-air .messenger-message .messenger-close:active {
  color: #222222;
}
/* line 58, ../../src/sass/messenger-theme-air.sass */
ul.messenger-theme-air .messenger-message .messenger-actions {
  float: none;
  margin-top: 10px;
}
/* line 62, ../../src/sass/messenger-theme-air.sass */
ul.messenger-theme-air .messenger-message .messenger-actions a {
  -webkit-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1), inset 0px 1px rgba(255, 255, 255, 0.05);
  -moz-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1), inset 0px 1px rgba(255, 255, 255, 0.05);
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1), inset 0px 1px rgba(255, 255, 255, 0.05);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  text-decoration: none;
  display: inline-block;
  padding: 10px;
  color: #888888;
  margin-right: 10px;
  padding: 3px 10px 5px;
  text-transform: capitalize;
}
/* line 73, ../../src/sass/messenger-theme-air.sass */
ul.messenger-theme-air .messenger-message .messenger-actions a:hover {
  -webkit-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1), inset 0px 1px rgba(255, 255, 255, 0.15);
  -moz-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1), inset 0px 1px rgba(255, 255, 255, 0.15);
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1), inset 0px 1px rgba(255, 255, 255, 0.15);
  color: #444444;
}
/* line 77, ../../src/sass/messenger-theme-air.sass */
ul.messenger-theme-air .messenger-message .messenger-actions a:active {
  -webkit-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.18), inset 0px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.18), inset 0px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.18), inset 0px 1px rgba(0, 0, 0, 0.05);
  background: rgba(0, 0, 0, 0.04);
  color: #444444;
}
/* line 82, ../../src/sass/messenger-theme-air.sass */
ul.messenger-theme-air .messenger-message .messenger-actions .messenger-phrase {
  display: none;
}
/* line 85, ../../src/sass/messenger-theme-air.sass */
ul.messenger-theme-air .messenger-message .messenger-message-inner:before {
  -webkit-box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  left: 17px;
  display: block;
  content: " ";
  top: 50%;
  margin-top: -8px;
  height: 13px;
  width: 13px;
  z-index: 20;
}
/* line 99, ../../src/sass/messenger-theme-air.sass */
ul.messenger-theme-air .messenger-message.alert-success .messenger-message-inner:before {
  background-color: #5fca4a;
}
/* line 32, ../../src/sass/messenger-spinner.scss */
ul.messenger-theme-air .messenger-message.alert-error.messenger-retry-soon .messenger-spinner {
  width: 24px;
  height: 24px;
  background: transparent;
}
/* line 37, ../../src/sass/messenger-spinner.scss */
ul.messenger-theme-air .messenger-message.alert-error.messenger-retry-soon .messenger-spinner .messenger-spinner-side .messenger-spinner-fill {
  background: #dd6a45;
  -webkit-animation-duration: 20s;
  -moz-animation-duration: 20s;
  -ms-animation-duration: 20s;
  -o-animation-duration: 20s;
  animation-duration: 20s;
  opacity: 1;
}
/* line 45, ../../src/sass/messenger-spinner.scss */
ul.messenger-theme-air .messenger-message.alert-error.messenger-retry-soon .messenger-spinner:after {
  content: "";
  background: white;
  position: absolute;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  top: 2px;
  left: 2px;
  display: block;
}
/* line 32, ../../src/sass/messenger-spinner.scss */
ul.messenger-theme-air .messenger-message.alert-error.messenger-retry-later .messenger-spinner {
  width: 24px;
  height: 24px;
  background: transparent;
}
/* line 37, ../../src/sass/messenger-spinner.scss */
ul.messenger-theme-air .messenger-message.alert-error.messenger-retry-later .messenger-spinner .messenger-spinner-side .messenger-spinner-fill {
  background: #dd6a45;
  -webkit-animation-duration: 600s;
  -moz-animation-duration: 600s;
  -ms-animation-duration: 600s;
  -o-animation-duration: 600s;
  animation-duration: 600s;
  opacity: 1;
}
/* line 45, ../../src/sass/messenger-spinner.scss */
ul.messenger-theme-air .messenger-message.alert-error.messenger-retry-later .messenger-spinner:after {
  content: "";
  background: white;
  position: absolute;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  top: 2px;
  left: 2px;
  display: block;
}
/* line 109, ../../src/sass/messenger-theme-air.sass */
ul.messenger-theme-air .messenger-message.alert-error .messenger-message-inner:before {
  background-color: #dd6a45;
}
/* line 113, ../../src/sass/messenger-theme-air.sass */
ul.messenger-theme-air .messenger-message.alert-info .messenger-message-inner:before {
  background-color: #61c4b8;
}
/* line 116, ../../src/sass/messenger-theme-air.sass */
ul.messenger-theme-air .messenger-spinner {
  display: block;
  position: absolute;
  left: 12px;
  top: 50%;
  margin-top: -13px;
  z-index: 999;
  height: 24px;
  width: 24px;
  z-index: 10;
}


ul.messenger-theme-air .messenger-message{
  background-color: $white;
}

.location-selector {
  width: 100%;
  height: 220px;
  border: 1px dashed #bbb;
  background-color: $white;
  position: relative; }
.location-selector .bit {
  background-color: $gray-lighter;
  @include transition(background-color .15s ease-in-out);
  cursor: pointer;
  position: absolute; }
.location-selector .bit:hover {
  background-color: $gray-semi-lighter; }
.location-selector .bit.top, .location-selector .bit.bottom {
  height: 25%;
  width: 40%;
  margin: 0 30%; }
.location-selector .bit.top {
  top: 0; }
.location-selector .bit.bottom {
  bottom: 0; }
.location-selector .bit.right, .location-selector .bit.left {
  height: 20%;
  width: 20%;
  margin-left: 0;
  margin-right: 0; }
.location-selector .bit.right {
  right: 0; }
.location-selector .bit.left {
  left: 0; }


/***********************/
/*         Flot        */
/***********************/


.chart-tooltip{
  position: fixed;
  padding: $padding-small-vertical $padding-small-horizontal;
  border: 1px solid $gray-semi-lighter;
  font-size: $font-size-mini;
  background-color: $white;
}

/***********************/
/*   Easy Pie Chart    */
/***********************/


.easy-pie-chart{
  position: relative;
  display: inline-block;
  line-height: 110px;
  height: 110px;
  width: 110px;
  text-align: center;
  color: $gray-semi-light;
  canvas{
    position: absolute;
    top: 0;
    left: 0
  }
}

/***********************/
/*    Full Calendar    */
/***********************/

.fc th{
  text-transform: uppercase;
}

.fc-event {
  padding: 2px 3px;
  border: none;
  font-weight: $font-weight-normal;
  background-color: $gray-lighter;
  color: $text-color;
}

.fc-today{
  background-color: #fff1b8;
}

a.fc-event{
  height: auto;
  line-height: $line-height-base;
}

/***********************/
/*      Live Tiles     */
/***********************/

.live-tile,
.list-tile,
.copy-tile,
.tile-strip .flip-list > li{
  height: auto;
  width: 100%;
  margin: 0;
  color: inherit;

  &.fade{
    opacity: 1;
  }
}

.live-tile p,
.list-tile p,
.copy-tile p {
  padding: 0;
}

.live-tile p,
.list-tile p,
.copy-tile p,
.live-tile .face,
.list-tile .face,
.copy-tile .face{
  /* let .h* classes work! */
  &.h1 { font-size: $font-size-h1; }
  &.h2 { font-size: $font-size-h2; }
  &.h3 { font-size: $font-size-h3; }
  &.h4 { font-size: $font-size-h4; }
  &.h5 { font-size: $font-size-h5; }
  &.h6 { font-size: $font-size-h6; }

  $font-sizes: $font-size-h1, $font-size-h2, $font-size-h3, $font-size-h4, $font-size-h5, $font-size-h6;
  $i: 1;
  @each $font-size in $font-sizes{
    &.value#{$i}{ font-size: $font-size; }
    $i: $i + 1;
  }
}

/***********************/
/*  bootstrap-select   */
/***********************/

.selectpicker.form-control {
  height: auto;
}

/***********************/
/*     Summernote      */
/***********************/

.note-editor {
  border: 1px solid $input-border;
  overflow: visible;
}

.note-link-dialog,
.note-image-dialog{
  .form-group {
    margin-left: 0;
    margin-right: 0;
  }
}

.note-editable {
  min-height: 120px;
}
